import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import RajImage from "../images/raj-by-rupy.png"
import EmailForm from "../components/email-form"


const Top = styled.div`
  text-align: center;
`

const Details = styled.div`
  text-align: left;
  margin: 10px;
`

const OuterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const NameHeader = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`

const Description = styled.p`
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`

const Logo = styled.img`
  height:200px;
`

const LandingBio = () => (
  <StaticQuery
    query={graphql`
      query LandingSiteTitleQuery {
        site {
          siteMetadata {
            title
            subtitle
          }
        }
      }
    `}
    render={data => (
      <OuterContainer>
        <Top>
          <Logo src={RajImage} />
          <NameHeader>{data.site.siteMetadata.title}</NameHeader>
          <Description>{data.site.siteMetadata.subtitle}</Description>
        </Top>
        <Details>
          <p>I got lucky and became a people leader early in my career. I was bad at it and my people suffered. But, I made sure to learn from every mistake. Now, I build engineering teams by focusing on my personal principles of <b>People, Recognition and Execution</b>.</p>
          <p><b>People.</b>  I develop leaders through compassionate coaching and a practical toolkit built from my mistakes.<br/></p>
          <p><b>Recognition.</b> I believe that purposeful recognition is the most underrated leadership skill.<br/></p>
          <p><b>Execution.</b>  I build data-driven teams that experiment quickly to drive outcomes.</p>
          A little bit more about me.
          <ul>
            <li> I’ve started multiple startups and also had an <a href="https://techcrunch.com/2011/02/23/disney-acquires-social-network-for-kids-togetherville/">exit</a> to Disney.</li>
            <li> I lead a few different engineering teams at Credit Karma.</li>
            <li> I think a lot about how you can become a better leader in a tech company.</li>
            <li> I coach aspiring leaders on the side and I really enjoy it. Please reach out if I can help.</li>
            <li> You can connect with me on <a href="https://www.linkedin.com/in/rajsinghtut">Linkedin</a> or <a href="https://www.twitter.com/rajsinghtut">Twitter</a></li>
          </ul>
        </Details>
        <EmailForm/>
      </OuterContainer>
    )}
  />
)

NameHeader.propTypes = {
  siteTitle: PropTypes.string,
  subtitle: PropTypes.string,
}

NameHeader.defaultProps = {
  siteTitle: ``,
  subtitle: ``,
}

export default LandingBio
