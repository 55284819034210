/* eslint no-console:0 no-alert:0 */
import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import styled from "@emotion/styled"

const Form = styled.form`
  display: flex;
  flex-direction: column;

  background: #f2f2f2;
  color: #2a2a2a;

  padding: 2rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: #2a2a2a;
`

const SubscribeButton = styled.button`
  display: inline-block;
  
  border: none;
  background-image: none;
  background-color: #8C1515;
  color: white;

  transition: all 0.1s linear;
  
  &:hover {
    cursor: pointer;
    background: darken(#8C1515, 15%);
  } 
`

export default class EmailForm extends React.Component {
  state = {
    email: null
  }

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`)

        if (result !== 'success') {
          throw msg
        }
        alert(msg)
      })
      .catch(err => {
        console.log('err', err)
        alert(err)
      })
  }

  render() {
    return (
      <div>
        <div>
          <Form onSubmit={this._handleSubmit}>
            <h5>Get emailed new posts.</h5>
            <Wrapper>
              <input
                type="email"
                onChange={this._handleChange}
                placeholder="email"
                name="email"
                aria-label="Subscribe via email"
              />
              <SubscribeButton type="submit">Subscribe</SubscribeButton>
            </Wrapper>
          </Form>
        </div>
      </div>
    )
  }
}